/* istanbul ignore file */
import React, { useLayoutEffect, useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    RESOURCE_NAME,
    RESOURCE_NAMESPACE,
    routeConfig,
    useIntl,
    useMessages,
    usePermissions,
    useUserProfile,
    getMuiTheme,
    useEnvironment
} from '@amzn/austin-core';
import { ThemeProvider } from '@mui/material/styles';
import { routeConfig as routes } from './common/routeConfig';
import Routes from './Routes';
import IncidentSiteLaunchCheck from './components/IncidentSiteLaunchCheck';

import { getAppRegionFromHostname, ENVIRONMENT } from './common/environment';
import {
    fetchFeatureFlags,
    getEnabledFeatureFlagsFromSession
} from './common/helpers/featureFlags';
import { OTR_APP_CONFIG_CONFIGURATION } from './common/typeDefs/incident';
import { IncidentHelpPanelProvider } from './common/hooks/incidentHelpPanelContext';
import { SiteDataProvider } from './common/hooks/siteDataContext';

// @todo: temp auto enabling of feature flag for uat features
const UAT_ENVIRONMENTS = [
    ENVIRONMENT.GAMMA_EU,
    ENVIRONMENT.GAMMA_FE,
    ENVIRONMENT.GAMMA_NA,
    ENVIRONMENT.GAMMA_EU_AWS,
    ENVIRONMENT.GAMMA_FE_AWS,
    ENVIRONMENT.GAMMA_NA_AWS
];

const IncidentsContainer = memo((props) => {
    const [hasTranslationsLoaded, setHasTranslationsLoaded] = useState(false);

    const { isMobilePhone } = useEnvironment();
    const { direction, locale, setTranslations } = useIntl();
    const theme = getMuiTheme(isMobilePhone, direction);

    const { enqueueMessage } = useMessages();
    const {
        getUsername,
        getUserSite,
        isSiteUser,
        userProfile
    } = useUserProfile();
    const { setRouteConfig } = routeConfig(RESOURCE_NAMESPACE.INCIDENTS);

    // check access to incidents module
    const { canView, canManage, canEdit, canVerify } = usePermissions(
        RESOURCE_NAMESPACE.INCIDENTS,
        RESOURCE_NAME.INCIDENT
    );

    const userSiteId = isSiteUser ? getUserSite()?.value : undefined;
    const userSitePermissions = userProfile.permissions.find(
        ({ siteConfigContext }) =>
            siteConfigContext.nodeIds.find(({ id }) => id === userSiteId)
    );

    // @todo: temp auto enabling of feature flag for PAPI integration UAT
    const appRegion = getAppRegionFromHostname();
    const isUATDomain = UAT_ENVIRONMENTS.includes(appRegion?.domain);
    useLayoutEffect(() => {
        handleUpdateLocale(locale);

        if (isUATDomain) {
            // ignore - used for auto setting uat feature flags
            // localStorage.setItem(FEATURE_FLAG_NAME, true);
        }
    }, [locale]);

    // Fetch feature flags from BE and store in session storage
    useEffect(() => {
        const featureFlags = getEnabledFeatureFlagsFromSession();
        if (featureFlags === null) {
            fetchFeatureFlags(OTR_APP_CONFIG_CONFIGURATION);
        }
    }, []);

    const attributes = {
        isSiteUser,
        username: getUsername(),
        userSiteId,
        userSitePermissions,
        canView,
        canEdit,
        canManage,
        canReview: canVerify,
        enableExperimentalFeatures: true
    };

    setRouteConfig({
        attributes,
        basePath: props.basePath,
        routes
    });

    async function loadLanguageFile(nextLocale) {
        // eslint-disable-next-line no-unused-vars
        return new Promise((res) => {
            import(`./common/languages/${nextLocale}.json`).then((data) =>
                res(data?.default?.resources ?? {})
            );
        });
    }

    async function handleUpdateLocale(nextLocale) {
        try {
            const translations = await loadLanguageFile(nextLocale);
            setTranslations(translations);
            setHasTranslationsLoaded(true);
        } catch (error) {
            enqueueMessage(error);
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }

    if (!hasTranslationsLoaded) {
        return null;
    }

    return (
        <ThemeProvider theme={theme}>
            <IncidentSiteLaunchCheck siteId={userSiteId}>
                <IncidentHelpPanelProvider>
                    <SiteDataProvider>
                        <Routes data-testid="IncidentsContainer" {...props} />
                    </SiteDataProvider>
                </IncidentHelpPanelProvider>
            </IncidentSiteLaunchCheck>
        </ThemeProvider>
    );
});

IncidentsContainer.propTypes = {
    basePath: PropTypes.string
};

IncidentsContainer.defaultProps = {
    basePath: '/'
};

export default IncidentsContainer;
